import { useState } from "react";
import useStore from "../store";
import { Button, IconButton, Link, Stack, TextField, Typography } from "@mui/material";
import { theme_bgColorMain, theme_orange, theme_textColorBlended, theme_textColorMain } from "../Theme";

import NoteAltIcon from '@mui/icons-material/NoteAlt';
import { AutoDetectURLsAndReplaceWithLinks } from "../Globals";
import { SaveActiveAoi } from "./AoiOps";

const NOTE_MAX_LINES_WHEN_COLLAPSED = 3;
const NOTE_MAX_LINES_WHEN_EXPANDED  = 9;

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface AoiNoteProps
{
}

//-------------------------------------------------------------------------------
// AOI note component
//-------------------------------------------------------------------------------
const AoiNote = (props: AoiNoteProps) => 
{
  // Get needed state data from the store
  const { store_aoi, store_setAoiNote, store_project, 
        } = useStore();

  const [editNoteMode, setEditNoteMode] = useState<boolean>(false);
  const [editText, setEditText] = useState<string>('');
  const [isNoteExpanded, setIsNoteExpanded] = useState<boolean>(false);
  const [noteMaxLines, setNoteMaxLines] = useState<number>(NOTE_MAX_LINES_WHEN_COLLAPSED);







  //-------------------------------------------------------------------------------
  // 
  //-------------------------------------------------------------------------------
  function OnEditNote()
  {
    if(store_aoi && store_aoi.properties && store_aoi.properties.note)
      setEditText(store_aoi.properties.note.text);

    setEditNoteMode(true);
  }

  //-------------------------------------------------------------------------------
  // 
  //-------------------------------------------------------------------------------
  function OnSaveChanges()
  {
    store_setAoiNote(editText.trim());

    setEditText('');
    setEditNoteMode(false);

    SaveActiveAoi();
  }

  //-------------------------------------------------------------------------------
  // 
  //-------------------------------------------------------------------------------
  function OnCancel()
  {
    setEditText('');
    setEditNoteMode(false);
  }

  //-------------------------------------------------------------------------------
  // The user typed in the note text field.
  //-------------------------------------------------------------------------------
  const OnNoteChanged = (event: any) => 
  {
    const noteText: string = event.target.value as string;
    setEditText(noteText);
  }

  //-------------------------------------------------------------------------------
  // The "Expand..." link button was clicked.
  //-------------------------------------------------------------------------------
  function OnExpandNotesClick()
  {
    setNoteMaxLines(NOTE_MAX_LINES_WHEN_EXPANDED);
    setIsNoteExpanded(true);
  }

  //-------------------------------------------------------------------------------
  // The "Collapse..." link button was clicked.
  //-------------------------------------------------------------------------------
  function OnCollapseNotesClick()
  {
    setNoteMaxLines(NOTE_MAX_LINES_WHEN_COLLAPSED);
    setIsNoteExpanded(false);
  }


  




  if(!store_project || store_project.aois.length === 0 || !store_aoi) return null;

  
  // ******************************************************************************************
  // Main render
  // ******************************************************************************************

  return (

    <Stack direction='column' sx={{ mt: 2, width: '100%' }}>

      {editNoteMode
        ?
          // AOI Note edit mode

          <Stack>
            <TextField variant='standard' multiline focused autoFocus margin='none' size='small'
                       value={editText} onChange={OnNoteChanged} maxRows={6}
                       inputProps={{style: {fontSize: '0.7rem', color: theme_bgColorMain }}}
                       sx={{ borderRadius: 1, px:'4px', fontSize: '0.6rem', bgcolor: theme_textColorMain+'A0' }}/>

            <Stack direction='row' sx={{ mt: '5px', width: '100%', alignItems: 'center', justifyContent: 'end' }}>
              <Button variant='contained' onClick={_=>OnCancel()}
                      sx={{ p:0, textTransform: 'none', fontSize: '0.7rem' }}>
                Cancel
              </Button>
              <Button variant='contained' onClick={_=>OnSaveChanges()}
                      sx={{ ml: 1, px: 1, py: 0, textTransform: 'none', fontSize: '0.7rem', fontWeight: 'bold' }}>
                Save Changes
              </Button>
            </Stack>
          </Stack>
        :
          // AOI Note view mode

          <Stack direction='row' sx={{ width: 'calc(100% - 18px)', justifyContent: 'space-between', alignItems: 'start' }}>

            {store_aoi && store_aoi.properties && store_aoi.properties.note && store_aoi.properties.note.text && store_aoi.properties.note.text.length > 0
              ?
                // AOI Note has some text set

                <Stack sx={{ width: '100%', bgcolor: '#315D9C', borderRadius: 1, px: '4px', py: '2px' }}>
                  {!isNoteExpanded
                    ?
                      // The note is "Collapsed" - it only shows up to 3 lines, and no scrolling is possible
                      <Typography align='left' display='inline-block' whiteSpace='pre-line'
                                  sx={{ fontSize: '0.7rem', color: theme_textColorMain+'A0',
                                        overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitLineClamp: noteMaxLines, 
                                        WebkitBoxOrient: "vertical" }}>
                        {AutoDetectURLsAndReplaceWithLinks(store_aoi.properties.note.text, theme_textColorBlended)}
                      </Typography>
                    :
                      // The note is "Expanded" - it shows up to 9 lines with vertical scrolling
                      <Typography align='justify' display='inline-block' whiteSpace='pre-line'
                                  sx={{ fontSize: '0.7rem', color: theme_textColorMain+'A0',
                                        overflow: "auto", textOverflow: "ellipsis", display: "-webkit-box", WebkitLineClamp: noteMaxLines, 
                                        WebkitBoxOrient: "vertical" }}>
                        {AutoDetectURLsAndReplaceWithLinks(store_aoi.properties.note.text, theme_textColorBlended)}
                      </Typography>
                  }

                  {/* Expand / Collapse link button - only shows up if the note has more lines than can fit in "Collapsed" mode */}
                  {store_aoi.properties.note.text.split(/\r\n|\r|\n/).length > NOTE_MAX_LINES_WHEN_COLLAPSED
                    ?
                      !isNoteExpanded
                        ?
                          <Link component="button" underline="hover" onClick={(_)=>OnExpandNotesClick()}
                                sx={{ fontSize: '0.6rem', mt: '3px', opacity: 0.8, color: theme_orange }}>
                            &#11167; Expand...
                          </Link>
                        :
                          <Link component="button" underline="hover" onClick={(_)=>OnCollapseNotesClick()}
                                sx={{ fontSize: '0.6rem', mt: '3px', opacity: 0.8, color: theme_orange }}>
                            &#11165; Collapse...
                          </Link>
                    :null
                  }

                </Stack>
              :
                // AOI Note is empty
                <Typography sx={{ fontSize: '0.7rem', color: theme_textColorBlended, opacity: 0.8, ml: '27px' }}>
                  You can add notes for this AOI here →
                </Typography>
            }

            <IconButton sx={{ p: '2px', ml: '2px' }} onClick={(_)=>OnEditNote()}>
              <NoteAltIcon sx={{ fontSize: '18px', color: theme_textColorBlended }}/>
            </IconButton>

          </Stack>
      }

    </Stack>
  )

  // ******************************************************************************************
  // Main render - fall-through case, ideally should never get called.
  // ******************************************************************************************

  // return (
  //   <Box sx={{ color: theme_textColorBlended }}>
  //     AOI Note unhandled state
  //   </Box>
  // )

}


export default AoiNote;
