import { ReactNode, useState } from "react";
import useStore from "../store";
import { Box, Button, FormControl, IconButton, MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { DEV_MODE, FriendlyNumber } from "../Globals";
import { theme_limeGreen, theme_orange, theme_textColorBlended, theme_textColorMain } from "../Theme";
import { DeleteActiveAoi, EnterAoiEditMode, LoadAllProjectAois, LoadAoi, LoadAoiList, ZoomToActiveAOI } from "./AoiOps";
import AoiCreation from "./AoiCreation";
import AoiRename from "./AoiRename";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import FeatureButton from "../Components/FeatureButton";
import YesNoDialog from "../Components/YesNoDialog";
import AoiSelection from "./AoiSelection";
import AoiUpload, { FILE_UPLOAD_MAX_SIZE_BYTES, FILE_UPLOAD_MAX_SIZE_MB } from './AoiUpload';
import { ToastNotification } from "../ToastNotifications";
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import AoiFromParcels from "./AoiFromParcels";
import { EnterParcelsMode } from "../Parcels/ParcelOps";
import MyCircularProgress from "../Components/CircularProgress";
import AoiEdit from "./AoiEdit";
import TypographyWithAutoTooltip from "../Components/TypograpyWithAutoTooltip";
import VisibilityIcon from '@mui/icons-material/Visibility';
import AoiExport from "./AoiExport";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AoiNote from "./AoiNote";

const SHOW_ALL_AOIS_IN_PROJECT_AOI_ID = 999001;

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface AoisProps 
{
}
  
//-------------------------------------------------------------------------------
// AOI component
//-------------------------------------------------------------------------------
const Aois = (props: AoisProps) => 
{
  // Get needed state data from the store
  const { store_isLoggedIn, store_aoiUIMode, store_setAoiUIMode, 
          store_project, store_userProfileIsLoading, store_projectIsLoading, 
          store_aoiIsLoading, store_aoi, store_aoiListIsLoading, 
          store_aoiIsDeleting, 
          store_userInfo, 
        } = useStore();

  const [deleteAoiConfirmation, setDeleteAoiConfirmation] = useState(false);
  const [uploadFile, setUploadFile] = useState<File|null>(null);
  //const [editNoteMode, setEditNoteMode] = useState<boolean>(false);





  //-------------------------------------------------------------------------------
  // The user has selected a different AOI.
  //-------------------------------------------------------------------------------
  const onAoiChanged = async (event: SelectChangeEvent<number>, child: ReactNode) => 
  {
    if(!store_project) return;

    const clickedAoiID: string | number = event.target.value;
    if(typeof clickedAoiID !== 'number') return;

    if(clickedAoiID === SHOW_ALL_AOIS_IN_PROJECT_AOI_ID)
      LoadAllProjectAois();
    else
      LoadAoi(clickedAoiID);
  }

  //-------------------------------------------------------------------------------
  // Go into "Create new AOI" mode.
  //-------------------------------------------------------------------------------
  const onCreateNewAoiMode = async () => 
  {
    store_setAoiUIMode('create');
  }

  //-------------------------------------------------------------------------------
  // Go into "Rename active AOI" mode.
  //-------------------------------------------------------------------------------
  const onRenameActiveAoiMode = async () => 
  {
    store_setAoiUIMode('rename');
  }

  //-------------------------------------------------------------------------------
  // Confirm deletion of the active AOI.
  //-------------------------------------------------------------------------------
  const onConfirmDeleteAoi = async () => 
  {
    setDeleteAoiConfirmation(true);
  }

  //-------------------------------------------------------------------------------
  // Delete the active AOI (and remove it from all projects useing it).
  // 
  // NOTE: This will only be triggered following Yes/No confirmation from the user.
  //-------------------------------------------------------------------------------
  const onDeleteActiveAoi = async () => 
  {
    setDeleteAoiConfirmation(false);

    DeleteActiveAoi();
  }

  //-------------------------------------------------------------------------------
  // Import an AOI from another project.
  //-------------------------------------------------------------------------------
  const onAddSavedAoi = async () => 
  {
    LoadAoiList();
    
    store_setAoiUIMode('select')
  }

  //-------------------------------------------------------------------------------
  // Export the active AOI to file.
  //-------------------------------------------------------------------------------
  const onExportAoi = async () => 
  {
    store_setAoiUIMode('export')
  }

  //-------------------------------------------------------------------------------
  // The user has selected a file to upload.
  //-------------------------------------------------------------------------------
  const onUploadFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => 
  {
    if(!event.target.files || event.target.files.length === 0) return;

    const file: File = event.target.files[0];
    if(!file) return;

    if(!file.name) return;  // If the user cancels

    if(file.size > FILE_UPLOAD_MAX_SIZE_BYTES)
    {
      ToastNotification('error', `The file you selected is too big (over ${FILE_UPLOAD_MAX_SIZE_MB} MB)`);
      return;
    }

    setUploadFile(file);

    // Switch the UI to 'upload' mode
    store_setAoiUIMode('upload');
  }

  //-------------------------------------------------------------------------------
  // Enters "Create a new AOI by selecting parcels" mode.
  //-------------------------------------------------------------------------------
  const onEnterAoiFromParcelsMode = (event: React.ChangeEvent<HTMLInputElement>) => 
  {
    // If the current AOI is not in a saved state, tell the user to save it first

    const store_aoi = useStore.getState().store_aoi;
    if(store_aoi && store_aoi.isDirty)
    {
      ToastNotification('error',  'The active AOI is not saved.  Please save it first before creating a new AOI from selected parcels.');
      return false;
    }

    EnterParcelsMode();

    // Switch the AOI UI into "create from parcels" mode
    // This asks the user for the name of the new AOI.
    //
    // Once they hit OK, the AOI will be created (see: AoiFromParcels.tsx)

    store_setAoiUIMode('create-from-parcels');
  }

  //-------------------------------------------------------------------------------
  // Enters AOI edit mode.
  //-------------------------------------------------------------------------------
  function OnEnterAoiEditMode()
  {
    EnterAoiEditMode(true);
  }

  //-------------------------------------------------------------------------------
  // Zoom the map view to the active AOI's extent.
  //-------------------------------------------------------------------------------
  function OnZoomToActiveAOI()
  {
    ZoomToActiveAOI();
  }












  
  // ******************************************************************************************
  // Main render - a user is NOT logged in
  // ******************************************************************************************

  if(!store_isLoggedIn) 
  return (
    <Box sx={{ color: theme_textColorBlended }}>
      Please log in to see this section.
    </Box>
  )

  // ******************************************************************************************
  // Main render - LOADING state
  // ******************************************************************************************

  if(store_userProfileIsLoading || store_projectIsLoading || store_aoiIsLoading || store_aoiListIsLoading)
  return (
    <Stack direction='row' sx={{ alignItems: 'center' }}>
      <MyCircularProgress />
      <Typography sx={{ width: '100%', fontSize: '1rem', color: theme_textColorBlended, ml: 2, textTransform: 'none' }}>
        Loading...
      </Typography>
    </Stack>
  )

  // ******************************************************************************************
  // Main render - Active AOI is being deleted
  // ******************************************************************************************

  if(store_aoiIsDeleting)
  return (
    <Stack direction='row' sx={{ alignItems: 'center' }}>
      <MyCircularProgress />
      <Typography sx={{ width: '100%', fontSize: '1rem', color: theme_textColorBlended, ml: 2, textTransform: 'none' }}>
        Deleting AOI...
      </Typography>
    </Stack>
  )

  // ******************************************************************************************
  // Main render - SELECTION state (bring in a saved AOI from outside the active project)
  // ******************************************************************************************

  if(store_aoiUIMode === 'select')
  return (
     <AoiSelection />
  )

  // ******************************************************************************************
  // Main render - CREATE NEW state
  // ******************************************************************************************

  if(store_aoiUIMode === 'create') // || (store_project && store_project.aois.length === 0))
  return (
    <AoiCreation />
  )

  // ******************************************************************************************
  // Main render - CREATE FROM PARCELS state
  // ******************************************************************************************

  if(store_aoiUIMode === 'create-from-parcels') // || (store_project && store_project.aois.length === 0))
  return (
    <AoiFromParcels />
  )

  // ******************************************************************************************
  // Main render - RENAME state
  // ******************************************************************************************

  if(store_aoiUIMode === 'rename')
  return (
     <AoiRename />
  )
  
  // ******************************************************************************************
  // Main render - UPLOAD state
  // ******************************************************************************************

  if(store_aoiUIMode === 'upload')
  return (
     <AoiUpload uploadFile={uploadFile}/>
  )

  // ******************************************************************************************
  // Main render - EDIT state
  // ******************************************************************************************

  if(store_aoiUIMode === 'edit')
  return (
     <AoiEdit />
  )

  // ******************************************************************************************
  // Main render - EXPORT state
  // ******************************************************************************************

  if(store_aoiUIMode === 'export')
  return (
    <AoiExport />
  )

  // ******************************************************************************************
  // Main render - a saved AOI is active
  // ******************************************************************************************

  if(store_project && store_project.aois)
  return (

    <Stack direction='column' sx={{ width: '100%', mt: -1 }}>

      {/* AOI selection combo box */}

      {store_project.aois.length > 0
        ?
          <Stack>

            {/* <Typography sx={{ mt: -1, mb: 1, color: theme_textColorMain, fontSize: '0.7rem', opacity: 0.6 }}>
              The project can contain multiple AOIs, but only the selected AOI is used for Highest and Best Value calculations.
            </Typography> */}

            <FormControl fullWidth>

              {/* <InputLabel id="aoi-select-label" sx={{ color: theme_textColorBlended }}>Active Area of Interest (AOI)</InputLabel> */}

              <Select labelId="aoi-select-label" id="aoi-select" size='small'
                      value={store_aoi?.aoi_id} onChange={onAoiChanged}
                      sx={{ color: theme_orange, alignItems: 'center',
                           '& .MuiOutlinedInput-notchedOutline': { borderColor: theme_textColorBlended },
                           '& .MuiSvgIcon-root': { color: theme_textColorBlended }
                          }}>

                {store_project.aois.map(projectAoi =>
                  <MenuItem key={projectAoi.aoi_id} value={projectAoi.aoi_id}>
                    <Stack sx={{ minHeight: '30px', justifyContent: 'center' }}>
                      <Stack direction='row' sx={{ alignItems: 'center' }}>
                        <TypographyWithAutoTooltip noWrap placement='right' arrow
                                                   sx={{ color: theme_orange, fontSize: '0.8rem' }}>
                          {projectAoi.aoi_name}
                        </TypographyWithAutoTooltip>
                      </Stack>

                      <Stack direction='row' sx={{ justifyContent: 'left' }}>

                        {DEV_MODE
                          ?
                            <Typography sx={{ color: theme_textColorBlended, opacity: 0.9, mr: 1, fontSize: '0.6rem' }}>{projectAoi.aoi_id}</Typography>
                          :null
                        }

                        {projectAoi.created_by && store_userInfo && projectAoi.created_by !== `${store_userInfo.first_name} ${store_userInfo.last_name}`
                          ?
                            <Typography sx={{ fontSize: '0.6rem', textTransform: 'none', color: theme_limeGreen, opacity: 0.9 }}>
                              Created by { projectAoi.created_by}
                            </Typography>
                          :null
                        }
                      </Stack>

                    </Stack>
                  </MenuItem>
                )}

                {/* Special item to show all AOIs in this project at once */}

                <MenuItem key={SHOW_ALL_AOIS_IN_PROJECT_AOI_ID} value={SHOW_ALL_AOIS_IN_PROJECT_AOI_ID}>
                    <Stack sx={{ minHeight: '30px', justifyContent: 'center' }}>
                      <Stack direction='row' sx={{ alignItems: 'center' }}>
                        <TypographyWithAutoTooltip noWrap placement='right' arrow
                                                   sx={{ color: theme_textColorBlended, fontSize: '0.8rem', opacity: 0.9 }}>
                          Show all AOIs in this project
                        </TypographyWithAutoTooltip>
                      </Stack>

                      <Typography sx={{ fontSize: '0.6rem', textTransform: 'none', color: theme_textColorMain, opacity: 0.5 }}>
                        While this option is selected you cannot run HBV calculations
                      </Typography>

                    </Stack>
                  </MenuItem>

              </Select>
            </FormControl>

            <Stack direction='row' sx={{ mt:1, justifyContent: 'space-between',  alignItems: 'center' }}>

              <Stack direction='row' sx={{ fontSize: '0.7rem', opacity: 0.8, color: theme_textColorMain, alignItems: 'center' }}>
                <Typography sx={{ fontSize: '0.8rem', color: theme_orange, fontWeight: 'bold', ml: 0.5, mr: 0.5 }}>{store_aoi?.polygonCount}</Typography>
                section{store_aoi?.polygonCount===1?'':'s'} 
                <Typography sx={{ fontSize: '0.8rem', color: theme_orange, fontWeight: 'bold', ml: 0.5, mr: 0.5 }}>{FriendlyNumber(store_aoi?.acres)}</Typography>
                acres
              </Stack>

              <Stack direction='row' sx={{ alignItems: 'center' }}>

                <Button size='small' variant={'outlined'} onClick={() => { OnEnterAoiEditMode(); }}
                          sx={{ fontSize: '0.7rem', px: 0.5, py: 0, textTransform: 'none', minWidth: '35px', maxWidth: '35px' }}>
                  Edit
                </Button>


                {store_aoi && store_aoi.polygonCount > 0
                  ?
                    <IconButton sx={{ p:0.2, ml:1 }} onClick={(_)=>OnZoomToActiveAOI()}>
                      <VisibilityIcon sx={{ fontSize: '18px', color: theme_textColorBlended }}/>
                    </IconButton>
                  :null
                }
              </Stack>
            </Stack>

          </Stack>
        :
          <Typography sx={{ fontSize: '0.9rem', color: theme_textColorBlended }}>
            The next step is to define your Area of Interest (AOI) by using one of these methods:
          </Typography>
      }

      {/* AOI Note UI */}

      <AoiNote/>

      {/* Buttons */}

      <Stack direction='column' sx={{ mt: 2, width: '100%', justifyContent: 'space-around'}}>

        <FeatureButton onClick={onCreateNewAoiMode} icon={<AddIcon sx={{ color: theme_limeGreen, width: '20px', height: '20px', mr: '6px' }}/>}>
          Draw a new AOI on the map
        </FeatureButton>

        <FeatureButton onClick={onEnterAoiFromParcelsMode} icon={<HighlightAltIcon sx={{ color: theme_limeGreen, width: '20px', height: '20px', mr: '6px' }}/>}>
          Create an AOI by selecting parcels
        </FeatureButton>

        {/* Upload file button - It triggers the file selection, then switches to the 'upload' UI mode, and 
            the rest is all done inside the AoiUpload component. */}

        <label htmlFor="upload-file">

          <input style={{ display: 'none' }} id="upload-file" name="upload-file" type="file" onChange={onUploadFileSelected}
                  accept=".geojson,.json, application/geo+json" />

          <Button variant='text' component="span"
                  sx={{ pl: '3px', pr: '3px', pt: '2px', pb: '2px', color: theme_limeGreen, 
                        fontSize : '0.8rem', alignItems: 'center', textTransform: 'none' }}>
            <DriveFolderUploadIcon sx={{ color: theme_limeGreen, width: '20px', height: '20px', mr: '6px' }}/>
            Import an AOI from a GeoJSON file
          </Button>
        </label>

        <FeatureButton onClick={onAddSavedAoi} icon={<SavedSearchIcon sx={{ color: theme_limeGreen, width: '20px', height: '20px', mr: '6px' }}/>}>
          Import AOI from another project
        </FeatureButton>

        <FeatureButton onClick={onExportAoi} icon={<FileDownloadIcon sx={{ color: theme_limeGreen, width: '20px', height: '20px', mr: '6px' }}/>}>
          Export the active AOI
        </FeatureButton>

        {store_project.aois.length > 0
          ?
            <Stack sx={{ mt: 2 }}>
              <FeatureButton onClick={onRenameActiveAoiMode} icon={<EditIcon sx={{ color: theme_limeGreen, width: '20px', height: '20px', mr: '6px' }}/>}>
                Rename this AOI
              </FeatureButton>

              <FeatureButton onClick={onConfirmDeleteAoi} icon={<DeleteIcon sx={{ color: theme_limeGreen, width: '20px', height: '20px', mr: '6px' }}/>}>
                Delete this AOI
              </FeatureButton>
            </Stack>
          :null
        }
      </Stack>

      {/* "Delete AOI" Yes/No confirmation dialog */}

      <YesNoDialog open={deleteAoiConfirmation} setOpen={setDeleteAoiConfirmation}
                   onYes={onDeleteActiveAoi}>
        <Stack direction='row' sx={{ alignItems: 'center' }}>
          <DeleteIcon sx={{ mr: 2, color: theme_textColorBlended }} />
          <Box sx={{ color: theme_textColorMain }}>Delete area</Box>
          <Box sx={{ ml: 1, color: theme_orange, fontWeight: 'bold' }}>{store_aoi?.aoi_name}</Box>
          <Box sx={{ ml: 1, color: theme_textColorMain }}>?</Box>
        </Stack>
      </YesNoDialog>
      
    </Stack>
  )

  // ******************************************************************************************
  // Main render - fall-through case, ideally should never get called.
  // ******************************************************************************************

  return (
    <Box sx={{ color: theme_textColorBlended }}>
      Aois unhandled state
    </Box>
  )

};


export default Aois;
